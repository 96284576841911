import React from 'react';
import ReactDOM from 'react-dom/client';
import './Components/index.css';
import reportWebVitals from './reportWebVitals';
import {HelmetProvider} from "react-helmet-async";
import Main from "./Main";

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
        <React.StrictMode>
            <HelmetProvider>
                <Main />
            </HelmetProvider>
    </React.StrictMode>);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
